@import 'src/utils/utils';

.intro {
  @include zero;
  @include box;
  margin-top: 30px;
  margin-bottom: 40px;
  padding-right: 16px;
  padding-left: 16px;
  color: $Main-2;

  @media(min-width: $lg) {
    margin-top: 0;
    padding: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid $Main-2;
  }
  @media(min-width: $xxl) {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  &__header {
    @include Head-45-bold;
    @include zero;
    margin-bottom: 20px;

    @media(min-width: $lg) {
      @include Head-60-bold;
    }
  }

  &__content {
    display: grid;
    row-gap: 10px;

    @media(min-width: $lg) {
      max-width: 500px;
      row-gap: 10px;
    }
  }

  &__sub-header {
    @include zero;
    @include Text-16-bold;

    @media(min-width: $lg) {
      @include Text-18-bold;
    }
  }

  &__text {
    @include zero;
    @include Text-14-reg;
  }
}